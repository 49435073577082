/* eslint-disable no-unused-vars */
import React from 'react';
import { Flex, Image, Link, Text, ThemeProvider, jsx } from 'theme-ui';

import logo from '../assets/logo-yellow.svg';
import { SEO } from '../components';
import { STRINGS } from '../constants';
import theme from '../theme';

export default function Custom404() {
  const headerRedirect = '/';

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Flex sx={styles.container}>
        <Flex sx={styles.titleBox}>
          <Image sx={styles.image} src={logo} />
          <Text sx={styles.error}>{STRINGS.common.text404}</Text>
        </Flex>
        <Flex sx={styles.textBox}>
          <Text sx={styles.title}>{STRINGS.common.pageNotFound}</Text>
          <Link sx={styles.link} path={headerRedirect} href={headerRedirect}>
            {STRINGS.common.goBackToMainPage}
          </Link>
        </Flex>
      </Flex>
    </ThemeProvider>
  );
}

const styles = {
  container: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleBox: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '25em',
  },
  error: {
    fontSize: 10,
    fontWeight: 'bold',
    color: 'text',
  },
  textBox: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    color: 'text',
  },
  link: {
    textDecoration: 'none',
    color: 'text',
  },
};
